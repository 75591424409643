body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.splash{
  width:30%;
}

/* ---------------- DARK MODE -----------------------------------------------------*/
.dark-mode__toggle{
  position: relative;
  display: inline
}

.dark-mode{
  color:#fff   !important;
  background-color: #071e22;
}


/* DarkMode Landing Page */

/* Font Colors */
.dark-mode .about-content h1,
.dark-mode .about-content h2,
.dark-mode .top-users-head,
.dark-mode .jumbo-head,
.dark-mode .subhead{
  color:  c;
}

/* Background color */
.dark-mode .aboutStyles,
.dark-mode .about-header,
.dark-mode .top-comments,
.dark-mode .signUpForm,
.dark-mode .top-users,
.dark-mode .jumbotron{
  background-color: #485461;
  background-image: linear-gradient(315deg, #485461 0%, #28313b 74%)  !important;
}


/* Sign-up/Login font color */
.dark-mode .signUpInput {
  color: #000000;
}

/* User card background color*/
.dark-mode .divOutsideMap{
  background-color: #314158;

}

/* DarkMode Footer */
.dark-mode footer{
  background-color: #314158;
  color:  #fe8a71;
  font-size: 1.2rem;
}

/* DarkMode Button Placement */
.darkRow {
  justify-content: flex-end;
}

.darkWrap {
  display:flex;
  flex-direction: row;
  margin-right: 5%; 
  /* align-items: flex-start; */
}


#salt{
  margin-right: 7px;
}

/* .fitted .slider . */

.fitted {
  border: 2px solid #fe8a71;
  height: 29px;
  width: 60px;
  padding:  3px;
  margin-right: 10px;
  border-radius: 50px;
  background-color: grey;

} 

/* Sign Up CSS Below --------------------------------------------------------------*/

/* Color HexCodes: **

Orange: #F97E03
Black: #000000
Paco-Grey: #4A3E36
Almond Frost-grey: #998070
White: #FFFFFF; 
*/


/* Forms centered using Flex and Align Items: center w/ flex-direction:column */
/* Form is the ENTIRE Form w/ inputs*/


.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F97E03;
  background-size: contain; 
  padding: 2rem;
}

/* Each individual input shares the same className to make things easier */
/* inputs */
.signUpInput{
  width: 30rem;
  background: white;
  padding: 0.8rem;
  border-radius: 3em;
  margin: 1.3rem;
  height: 6.5rem;

}

/*  Button */
.signUpSubmitBtn {
  width: 12rem;
  height: 4rem;
  color: white;
  border: 3px solid #F97E03;
  background-color: #6c757d;
  border-radius: 3em !important;
  margin-bottom: -2rem;
  margin-top: 1rem;
  font-size: 1.4rem !important;
}
.signUpSubmitBtn:hover {
  color: white;
  background: #5f676e;
  transition: 1s;
}

/* Have an Account? Log In ! Link */
.logInLink{
  width: 35rem;
  color:  white;
  border: 3px dotted #998070;;
  background-color: #998070;
  border-radius: 3em;
  font-size: 1.6rem;
  padding: 1rem;
  font-weight: bold;
  margin: 2rem;
  height: 3rem;
}
.logInLink:hover{
  color: white;
  background-color:  #4A3E36;
  transition: 1s;
  border: 3px dotted #4A3E36;
}

/* Don't Have An Account? Sign Up Here! */
.signUpLink{
  width: 35rem;
  color:  white;
  border: 3px dotted #998070;
  background-color: #998070;
  border-radius: 3em;
  font-size: 1.6rem;
  padding: 1rem;
  font-weight: bold;
  margin: 2rem;
  margin-top: 4rem;
  height: 3rem;
}
.signUpLink:hover{
  color: white;
  background-color:  #4A3E36;
  transition: 1s;
  border: 3px dotted #4A3E36;
}

.signUpHeaderStyles{
 margin: 2rem;
 margin-top: 0rem;

}


/* -------------------------------------------------------------------------------- */

/* Login.js CSS Below--------------------------------------------------------------- */

/* This styles the input */
.loginComp{
  border: 3px solid white;
  width: 30rem;
  background: white;
  padding: 0.8rem;
  border-radius: 3em;
  margin: 1.3rem;
  height: 5rem;
  margin-top: 0;
}
/* 
.loginUserPass{
  width: 30rem;
  background: white;
  padding: 0.8rem;
  border-radius: 3em;
  margin: 1.3rem;
  height: 6.5rem; 
} */


.headerStyles{
  width: 40rem;
  color:  white;
  border: 3px dotted #4A3E36;
  background-color: #4A3E36;
  border-radius: 3em;
  font-size: 1.6rem;
  padding: 1rem;
  font-weight: bold;
  
}


/* -------------------------------------------------------------------------------- */



/* Header.js CSS Below------------------------------------------------------------- */

.active {
  color: #F97E03;
}

.logo {
  max-height: 75px;
}

.loginNav {
  border: 1px solid black;
}

.lg-out {
  color:white;
}

/* -------------------------------------------------------------------------------- */
/* About.js CSS Below-------------------------------------------------------------- */


.main-about-container{
  background-color: #F97E03;
  width: 100vw;
  display: flex;
  align-items: center;
}

.team-container {
  width: 880px;
  margin: 0 auto;
}

.team-members{
  padding-bottom: 50px;
  align-items: center;  
}

.team-members img {
    border-radius: 100px;
    width: 150px;
    height: 150px;
  }

  .aboutStyles {
    text-align: left;
    background-color: #F97E03;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    justify-items: center;
 
  }

  .aboutCard{
    width: 15rem !important;
    margin: 1rem !important;
  }

  .aboutCard:hover {
    transform: scale(1.08);
    box-shadow: 0 0 1rem #4A3E36 !important;
    border: 1px solid #4A3E36 !important;
  }


  .aboutCard{
    font-size: 1.2rem;
    border: 3px solid white !important;
    margin: 2rem !important;
    
  }

  .stylesLI{
    width: 4.7rem !important;
    
  }.jumbo-head {
  color: #fff;
  font-size: 5rem;
  font-weight: bold;
}

.subhead {
  color: #fff;
}

.top-users {
  background-color: #F97E03;
  border-radius: 20px;
  padding: 2%;
  width: 80%;
  margin: 0 auto;
  margin-top: 10%;
}

.top-users-head {
  color: #fff;
  font-weight: bold;
}

.top-comments {
  background-color: #F97E03;
  border-radius: 20px;
  padding: 2%;
  width: 80%;
  margin: 0 auto 2%;
}


/*--------------------------------------------------------------------------------- */
/* commentList below -------------------------------------------------------------- */


/*  div located in CommentList outside .map */
.divOutsideMap {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F97E03;
  padding: 2rem;
}


/*  div located in CommentList inside .map */

.divInsideMap {
  background-color: white;
  text-align: left;
  width: 60%;
  border-radius: 2em;
  margin: 0.5rem;
  padding: 1rem;
}

.divInsideMap:hover{
  transform: scale(1.03);
  box-shadow: 0 0 2rem #4A3E36;
  border: 2px solid #998070;
}

/* changing comment text size */
.commentComponent{
  font-size: 1.4rem;
}

/* Save  on Comments */
.commentSave{
  background-color: #998070;
  padding: 0.4rem;
  border-radius: 0.5em;
}

.commentSave:hover{
  transform: scale(1.1);
}

/* Up Vote Comments */
.commentUpVote {
  background-color: #998070;
  padding: 0.4rem;
  border-radius: 0.5em;
}
.commentUpVote:hover{
  transform: scale(1.1);
}

/* Down Vote Comments */
.commentDownVote{
  background-color: #998070;
  padding: 0.4rem;
  border-radius: 0.5em;
}
.commentDownVote:hover{
  transform: scale(1.1);
}

.usernameFontWeight{
  font-weight: bolder	;
  border-bottom: 1px solid #4A3E36;
  padding: 0.5rem;
}

/* -------------------------------------------------------------------------------------*/



/*--------------------------------------------------------------------------------- */
/* About.js Header styles -------------------------------------------------------------- */

.about-header{
  display: flex;
  /* flex-direction: column; */
  background-color:  #F97E03;
  padding: 2% 10%;
  margin: 0 auto;
}

.about-content{
  width: 55%;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.cardWrap{
  padding:2rem 0 0 0;
}

.hr{
 border: 1px solid white;
 width: 100%;
}

.hr-bottom{
  margin-bottom: 0;
}

.text-content{
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.3;
  padding-bottom: 15px;
  margin-right: 50px;
  margin-left: 50px;
}


footer{
  background-color: #4A3E36;
  padding: 2%;
  color: #ffffff;
}
/*--------------------------------------------------------------------------------- */
/* AccountCard styles -------------------------------------------------------------- */

.comment-delete{
  background:#4682B4;
  padding: 7px 15px;
}


.comment-edit{
  background:#4682B4;
  padding: 7px 15px;
}

.accountWrapper {
  background: #F97E03;
} */




/* Footer Styles below ---------------------------------------------------------------*/
footer {
  padding: 1%;
  background-color: #4A3E36
}
